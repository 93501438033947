import Nav from "./Nav";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { link } from "./url";
const Paymentupdate = () => {
  const { state } = useLocation();
  const [amount, setamount] = useState("");
  const [date, setdate] = useState("");
  const nav = useNavigate();
  const submitdata = () => {
    var value = {
      sid: state[0],
      amount: amount,
      pdate: date,
      balance: state[7],
      cid: state[1],
    };

    axios.post(link + "salman/addnegativebill", value).then((response) => {
      nav("/sales");
    });
  };
  return (
    <>
      <Nav />
      <div style={{ marginTop: "15%" }}>
        <h1>Add Bill</h1>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={state[0]}
            disabled
          />
          <label htmlFor="email">Bill No</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={state[1]}
            disabled
          />
          <label htmlFor="email">Customer No</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={state[2]}
            disabled
          />
          <label htmlFor="email">Product</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={state[3]}
            disabled
          />
          <label htmlFor="email">Price</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={state[7]}
            disabled
          />
          <label htmlFor="email">Balance</label>
        </div>

        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={amount}
            onChange={(e) => setamount(e.target.value)}
          />
          <label htmlFor="email">Enter Amount</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="date"
            className="form-control"
            value={date}
            onChange={(e) => setdate(e.target.value)}
          />
          <label htmlFor="email">Payment Date</label>
        </div>
        <input
          type="submit"
          className="btn btn-primary"
          onClick={submitdata}
          style={{ width: "100%" }}
        />
      </div>
    </>
  );
};

export default Paymentupdate;
