import Nav from "./Nav";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { link } from "./url";
const Addpurchase = () => {
  const [Data, setData] = useState([]);
  const [Price, setPrice] = useState("");
  const [Purchase, setPurchase] = useState("");
  const [value, setvalue] = useState("");
  useEffect(() => {
    axios.post(link + "salman/getname").then((response) => {
      setData(response.data);
    });
  }, []);
  const submitdata = () => {
    const values = { value: value, purchase: Purchase, price: Price };
    axios.post(link + "salman/addpurchase", values).then((response) => {
      alert("success");
      setPurchase("");
      setPrice("");
    });
  };
  const Search = (e) => {
    setvalue(e);
  };
  return (
    <>
      <Nav />
      <div style={{ marginTop: "15%" }}>
        <h1>Add Purchase</h1>
        <div className="form-floating mb-3 mt-3">
          <input
            list="browsers"
            id="browser"
            className="form-control"
            value={value}
            placeholder="Search"
            onChange={(e) => Search(e.target.value)}
          />
          <datalist id="browsers">
            {Data.map((s) => {
              return <option key={s}>{s}</option>;
            })}
          </datalist>
          <label htmlFor="email">Search data with name mobile or area</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setPrice(e.target.value)}
            value={Price}
            placeholder="Enter Price"
          />
          <label htmlFor="email">Price</label>
        </div>
        <div className="form-floating mt-3 mb-3">
          <input
            type="date"
            className="form-control"
            onChange={(e) => setPurchase(e.target.value)}
            value={Purchase}
            placeholder="Enter Area"
          />
          <label htmlFor="pwd">Sales Date</label>
        </div>
        <input
          type="submit"
          className="btn btn-primary"
          onClick={submitdata}
          style={{ width: "100%" }}
        />
      </div>
    </>
  );
};

export default Addpurchase;
