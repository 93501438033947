import axios from "axios";
import { useState } from "react";
import Nav from "./Nav";
import { link } from "./url";
const Addcustomer = () => {
  const [mobile, setMobile] = useState("");
  const [name, setname] = useState("");
  const [area, setarea] = useState("");
  const submitdata = () => {
    const value = { name: name, mobile: mobile, area: area };
    console.log(value);
    axios.post(link + "salman/add", value).then((res) => {
      setMobile("");
      setarea("");
      setname("");
      alert("stored successfully");
    });
  };
  return (
    <>
      <Nav />
      <div style={{ marginTop: "15%" }}>
        <h1>Add Customer</h1>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setname(e.target.value)}
            value={name}
            placeholder="Enter Name"
          />
          <label htmlFor="email">Name</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setMobile(e.target.value)}
            value={mobile}
            placeholder="Enter Mobile"
          />
          <label htmlFor="email">Mobile</label>
        </div>
        <div className="form-floating mt-3 mb-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setarea(e.target.value)}
            value={area}
            placeholder="Enter Area"
          />
          <label htmlFor="pwd">Area</label>
        </div>
        <input
          type="submit"
          className="btn btn-primary"
          onClick={submitdata}
          style={{ width: "100%" }}
        />
      </div>
    </>
  );
};

export default Addcustomer;
