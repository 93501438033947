import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import Nav from "./Nav";
import { link } from "./url";
const Allsales = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [value, setvalue] = useState([]);
  useEffect(() => {
    axios.get(link + "salman/allsales").then((response) => {
      setData(response.data);
      setvalue(response.data);
    });
  }, []);
  const deletec = (i) => {
    axios.post(link + "salman/deletesales", {
      id: i,
    });
    window.location.reload();
  };
  const searchdata = (e) => {
    const r = [];
    for (var k of value) {
      if (k[3] === null) {
        k[3] = "";
      }
      if (k[2] === null) {
        k[2] = "";
      }
      if (
        k[2].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        k[3].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        k[3].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        (k[4] + "").toLowerCase().indexOf(e.toLowerCase()) !== -1
      ) {
        r.push(k);
      }
    }
    setData(r);
  };
  const viewcustomer = (e) => {
    nav("/viewcustomer", { state: e });
  };
  const viewsales = (e) => {
    nav("/viewsales", { state: e });
  };
  return (
    <>
      <Nav />
      <div style={{ marginTop: "15%" }}>
        <NavLink className="nav-link" to="/sales">
          <button className="btn btn-primary">Current Customer</button>
        </NavLink>
        <h3>Completed Customer</h3>
        <input
          type="search"
          onChange={(e) => searchdata(e.target.value)}
          style={{ marginTop: "12%" }}
          className="form-select"
          placeholder="Search"
        />
        <div className="table-responsive">
          <table className="table table-bordered" id="table_id">
            <thead>
              <tr>
                <th>Salesid</th>
                <th>Cid</th>
                <th>Cname</th>
                <th>Area</th>
                <th>Mobile No</th>
                <th>Product Name</th>
                <th>Sales Date</th>
                <th>Day</th>
                <th>Pay Date</th>
                <th>Price</th>
                <th>Balance</th>
                <th>Payment date</th>
                <th>Change date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d) => {
                return (
                  <tr key={d[0]}>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => viewsales(d[0])}
                      >
                        {d[0]}
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => viewcustomer(d[1])}
                      >
                        {d[1]}
                      </button>
                    </td>
                    <td>{d[2]}</td>
                    <td>{d[3]}</td>
                    <td>{d[4]}</td>
                    <td>{d[5]}</td>
                    <td>{d[6]}</td>
                    <td>{d[7]}</td>
                    <td>{d[8]}</td>
                    <td>{d[9]}</td>
                    <td>{d[10]}</td>
                    <td>{d[11]}</td>
                    <td>{d[12]}</td>
                    <td>{d[13]}</td>
                    <td>
                      {window.localStorage.getItem("pro") === "salman" ? (
                        <button
                          className="btn btn-danger"
                          onClick={() => deletec(d[0])}
                        >
                          delete
                        </button>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Allsales;
