import { useLocation } from "react-router-dom";

import { useEffect } from "react";

const Printdata = () => {
  const { state } = useLocation();
  console.log(state);
  var e = 1;
  useEffect(() => {
    window.print();
  }, []);
  return (
    <>
      <p style={{ fontSize: "1.5vh", fontWeight: "bold" }} className="pr">
        {state.map((s) => {
          return (
            <span>
              {s[13] +
                "-" +
                e++ +
                "-" +
                s[3] +
                "-" +
                s[2] +
                "-" +
                s[4] +
                "-" +
                s[9]}
              <br />
            </span>
          );
        })}
      </p>
    </>
  );
};

export default Printdata;
