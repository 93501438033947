import { useLocation } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";
import { link } from "./url";
const BilPayment = () => {
  const nav = useNavigate();
  const [amount, setamount] = useState("");
  const { state } = useLocation();
  var MyDate = new Date();

  var MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + MyDate.getDate()).slice(-2);
  const [date1, setdate1] = useState("");
  const submitdata = () => {
    if (date1 === "" && amount !== "") {
      const value = {
        sid: state[0],
        cid: state[1],
        balance: state[9] - amount,
        paid: amount,
        paymentdate: MyDateString,
      };
      axios.post(link + "salman/addbill", value);
      nav("/bill");
    } else if (date1 !== "" && (amount === "" || amount === 0)) {
      const value = { sid: state[0], changedate: date1 };
      axios.post(link + "salman/nextbill", value);
      alert("Date changed successfully");
      nav("/bill");
    } else {
      const value = {
        sid: state[0],
        cid: state[1],
        balance: state[9] - amount,
        paid: amount,
        paymentdate: state[10],
      };
      axios.post(link + "salman/addbill", value);
      const value1 = { sid: state[0], changedate: date1 };
      axios.post(link + "salman/nextbill", value1);
      nav("/bill");
    }
  };
  const myfile = () => {
    return (
      <div className="form-floating mb-3 mt-3">
        <input
          type="date"
          className="form-control"
          value={date1}
          onChange={(e) => setdate1(e.target.value)}
        />
        <label htmlFor="email">Change Payment Date</label>
      </div>
    );
  };

  return (
    <>
      <Nav />
      <div style={{ marginTop: "15%" }}>
        <h1>Add Bill</h1>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={state[0]}
            disabled
          />
          <label htmlFor="email">Bill No</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={state[1] + "-" + state[2] + "-" + state[3] + "-" + state[4]}
            disabled
          />
          <label htmlFor="email">Customer</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={state[5]}
            disabled
          />
          <label htmlFor="email">Product</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={state[6]}
            disabled
          />
          <label htmlFor="email">Price</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={state[9]}
            disabled
          />
          <label htmlFor="email">Balance</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={state[10]}
            disabled
          />
          <label htmlFor="email">Payment Date</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={amount}
            onChange={(e) => setamount(e.target.value)}
          />
          <label htmlFor="email">Enter Amount</label>
        </div>
        {myfile()}
        <input
          type="submit"
          className="btn btn-primary"
          onClick={submitdata}
          style={{ width: "100%" }}
        />
      </div>
    </>
  );
};

export default BilPayment;
