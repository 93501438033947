import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "./Nav";
import axios from "axios";
import { link } from "./url";
const Viewsales = () => {
  const nav = useNavigate();
  const { state } = useLocation();
  const [bill, setbill] = useState([]);

  useEffect(() => {
    axios
      .post(link + "salman/viewbill", {
        sid: state[0],
      })
      .then((response) => {
        setbill(response.data.bill);
      });
  }, []);
  console.log(bill);
  const viewbill = (e) => {
    nav("/viewcustomer", { state: state[1] });
  };
  const payment = () => {
    nav("/paymentupdate", { state: state });
  };
  const Editsales = () => {
    nav("/editsales", { state: state });
  };
  return (
    <>
      <Nav />
      <div style={{ marginTop: "15%" }}>
        <button
          className="btn btn-danger"
          style={{ float: "right" }}
          onClick={viewbill}
        >
          Customer View
        </button>
        <br />
        <h3>Bill details</h3>
        <div className="table-responsive">
          <table className="table table-bordered" id="table_id">
            <thead>
              <tr>
                <th>Sid</th>
                <th>Pname</th>
                <th>Price</th>
                <th>Sales date</th>
                <th>Mode</th>
                <th>Date</th>
                <th>Balance</th>
                <th>NDate</th>
                <th>CDate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <button className="btn btn-danger" onClick={payment}>
                    {state[0]}
                  </button>
                </td>
                <td>{state[2]}</td>
                <td>{state[3]}</td>
                <td>{state[4]}</td>
                <td>{state[5]}</td>
                <td>{state[6]}</td>
                <td>{state[7]}</td>
                <td>{state[8]}</td>
                <td>{state[10]}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered" id="table_id">
            <thead>
              <tr>
                <th>Billid</th>
                <th>Total</th>
                <th>Balance</th>
                <th>Paid</th>
                <th>Paid Date</th>
              </tr>
            </thead>
            <tbody>
              {bill.map((d) => {
                return (
                  <tr key={d[0]}>
                    <td>{d[0]}</td>
                    <td>{state[3]}</td>
                    <td>{d[3]}</td>
                    <td>{d[4]}</td>
                    <td>{d[5]}</td>
                    <td>{d[6]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Viewsales;
