import Nav from "./Nav";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { link } from "./url";
const Purchasereport = () => {
  const [data, setData] = useState([]);
  const [value, setvalue] = useState([]);
  const [total, settotal] = useState(0);

  var MyDate = new Date();
  var MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + MyDate.getDate()).slice(-2);
  const [date, setdate] = useState(MyDateString);
  const [date1, setdate1] = useState(MyDateString);
  useEffect(() => {
    axios
      .post(link + "salman/getpurchase", {
        curdate: date,
      })
      .then((response) => {
        setvalue(Object.keys(response.data[0]));
        setData(response.data[0]);
        settotal(response.data[1]);
      });
  }, []);
  const submitdate = () => {
    axios
      .post(link + "salman/getall", {
        curdate: date,
        curdate1: date1,
      })
      .then((response) => {
        setvalue(Object.keys(response.data[0]));
        setData(response.data[0]);
        settotal(response.data[1]);
      });
  };
  const deletec = (i) => {
    axios.post(link + "salman/deletepurchase", {
      id: i,
    });
    window.location.reload();
  };

  return (
    <>
      <Nav />
      <div style={{ marginTop: "15%" }}>
        <div className="row">
          <div className="col-6">
            <input
              type="text"
              value={date}
              onChange={(e) => setdate(e.target.value)}
              className="form-select"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              value={date1}
              onChange={(e) => setdate1(e.target.value)}
              className="form-select"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-6">
            <input
              type="submit"
              onClick={submitdate}
              className="btn btn-danger"
              style={{ width: "100%" }}
            />
          </div>
          <br />
          <div className="col-3">
            <h5>Total -{total}</h5>
          </div>
        </div>
        {value.map((e) => {
          var r = 0;
          var re = 1;
          return (
            <div key={e}>
              <u>
                <h5>{e}</h5>
              </u>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sl No</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Date</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data[e].map((d) => {
                      r += d[2];
                      return (
                        <tr key={d[0]}>
                          <td>{re++}</td>
                          <td>{d[1]}</td>
                          <td>{d[2]}</td>
                          <td>{d[3]}</td>
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() => deletec(d[0])}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan={4}> total={r}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Purchasereport;
