import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "./Nav";
import axios from "axios";
import { link } from "./url";
const Viewcustomer = () => {
  const nav = useNavigate();
  const { state } = useLocation();
  const [cust, setCust] = useState([]);
  const [sales, setSales] = useState([]);

  useEffect(() => {
    axios
      .post(link + "salman/vieweachcustomer", {
        cid: state,
      })
      .then((response) => {
        setCust(response.data.customer);
        setSales(response.data.sales);
      });
  }, []);
  const viewbill = (e) => {
    nav("/viewsales", { state: e });
  };
  return (
    <>
      <Nav />
      <div style={{ marginTop: "15%" }}>
        <h3>Customer details</h3>
        <div className="table-responsive">
          <table className="table table-bordered" id="table_id">
            <thead>
              <tr>
                <th>Cid</th>
                <th>Customer Name</th>
                <th>Area</th>
                <th>Mobile no</th>
              </tr>
            </thead>
            <tbody>
              {cust.map((d) => {
                return (
                  <tr key={d[0]}>
                    <td>{d[0]}</td>
                    <td>{d[1]}</td>
                    <td>{d[2]}</td>
                    <td>{d[3]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered" id="table_id">
            <thead>
              <tr>
                <th>Sid</th>
                <th>Pname</th>
                <th>Price</th>
                <th>Sales date</th>
                <th>Mode</th>
                <th>Date</th>
                <th>Balance</th>
                <th>NDate</th>
                <th>CDate</th>
              </tr>
            </thead>
            <tbody>
              {sales.map((d) => {
                return (
                  <tr key={d[0]}>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => viewbill(d)}
                      >
                        {d[0]}
                      </button>
                    </td>
                    <td>{d[2]}</td>
                    <td>{d[3]}</td>
                    <td>{d[4]}</td>
                    <td>{d[5]}</td>
                    <td>{d[6]}</td>
                    <td>{d[7]}</td>
                    <td>{d[8]}</td>
                    <td>{d[10]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Viewcustomer;
