import { useLocation, useNavigate } from "react-router-dom";
import Nav from "./Nav";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { link } from "./url";
const Editsales = () => {
  const { state } = useLocation();
  const nav = useNavigate();
  const [Id] = useState(state[0]);
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [area, setArea] = useState("");
  const [Product, setProduct] = useState(state[2]);
  const [Price, setPrice] = useState(state[3]);
  const [Sales, setSales] = useState(state[4]);
  const [Mode, setMode] = useState(state[5]);
  const [week, setWeek] = useState(state[6]);
  const [balance, setbalance] = useState(state[7]);
  const [ori, setOri] = useState(state[8]);
  useEffect(() => {
    axios
      .post(link + "salman/eachcustomer", {
        cid: state[1],
      })
      .then((response) => {
        setName(response.data[0][1]);
        setMobile(response.data[0][3]);
        setArea(response.data[0][2]);
      });
  });
  const submitdata = () => {
    const value = {
      sid: Id,
      cid: state[1],
      pname: Product,
      price: Price,
      salesdate: Sales,
      day: Mode,
      mode: week,
      balance: balance,
      original: ori,
      name: name,
      area: area,
      mobileno: mobile,
    };
    axios.post(link + "salman/updatedata", {
      value,
    });
    nav("/sales");
  };
  const checkmode = () => {
    if (Mode === "Weekly") {
      return (
        <div className="form-floating mt-3 mb-3">
          <select
            className="form-select"
            setvalue={week}
            onChange={(e) => setWeek(e.target.value)}
          >
            <option>sunday</option>
            <option>monday</option>
            <option>tuesday</option>
            <option>wednesday</option>
            <option>thursday</option>
            <option>friday</option>
            <option>saturday</option>
          </select>
          <label htmlFor="email">Select Day for collection</label>
        </div>
      );
    } else if (Mode === "Monthly") {
      return (
        <div className="form-floating mt-3 mb-3">
          <input
            type="text"
            className="form-control"
            value={week}
            onChange={(e) => setWeek(e.target.value)}
            placeholder="enter collection date"
          />
          <label htmlFor="email">Select Date for collection</label>
        </div>
      );
    }
  };

  return (
    <>
      <Nav />
      <div style={{ marginTop: "15%" }}>
        <h1>Add Sales</h1>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={Id}
            placeholder="Enter Name"
            disabled
          />
          <label htmlFor="email">Salesid</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={name}
            placeholder="Enter Name"
            onChange={(e) => setName(e.target.value)}
          />
          <label htmlFor="email">Name</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={mobile}
            placeholder="Enter Mobile"
            onChange={(e) => setMobile(e.target.value)}
          />
          <label htmlFor="email">Mobile</label>
        </div>
        <div className="form-floating mt-3 mb-3">
          <input
            type="text"
            className="form-control"
            value={area}
            placeholder="Enter Area"
            onChange={(e) => setArea(e.target.value)}
          />
          <label htmlFor="pwd">Area</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setProduct(e.target.value)}
            value={Product}
            placeholder="Enter Product"
          />
          <label htmlFor="email">Product</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setPrice(e.target.value)}
            value={Price}
            placeholder="Enter Price"
          />
          <label htmlFor="email">Price</label>
        </div>
        <div className="form-floating mt-3 mb-3">
          <input
            type="date"
            className="form-control"
            onChange={(e) => setSales(e.target.value)}
            value={Sales}
            placeholder="Enter Area"
          />
          <label htmlFor="pwd">Sales Date</label>
        </div>
        <div className="form-floating mt-3 mb-3">
          <select
            onChange={(e) => setMode(e.target.value)}
            className="form-select"
            setvalue={Mode}
          >
            <option>Daily</option>
            <option>Monthly</option>
            <option>Weekly</option>
            <option>Continue</option>
          </select>
        </div>
        <div className="form-floating mt-3 mb-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setbalance(e.target.value)}
            value={balance}
            placeholder="Enter Area"
          />
          <label htmlFor="pwd">Balance</label>
        </div>
        <div className="form-floating mt-3 mb-3">
          <input
            type="date"
            className="form-control"
            onChange={(e) => setOri(e.target.value)}
            value={ori}
            placeholder="Enter Area"
          />
          <label htmlFor="pwd">Original Date</label>
        </div>

        {checkmode()}

        <input type="submit" className="btn btn-primary" onClick={submitdata} />
      </div>
    </>
  );
};

export default Editsales;
