import { Route, Routes } from "react-router-dom";
import Customer from "./Customer";
import Addcustomer from "./Addcustomer";
import AddSales from "./AddSales";
import Sales from "./Sales";
import Bill from "./Bill";
import Billreport from "./Billreport";
import BillPayment from "./BillPayment";
import Printdata from "./Printdata";
import Addpurchase from "./Addpurchase";
import Purchasereport from "./Purchasereport";
import Viewcustomer from "./Viewcustomer";
import Viewsales from "./ViewSales";
import Allsales from "./Allsales";
import Paymentupdate from "./Paymentupdate";
import Editsales from "./Editsales";
import Login from "./Login";
const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Customer />} />
        <Route path="/addcustomer" element={<Addcustomer />} />
        <Route path="/viewsales" element={<Viewsales />} />
        <Route path="/viewcustomer" element={<Viewcustomer />} />
        <Route path="/allsales" element={<Allsales />} />
        <Route path="/addsales" element={<AddSales />} />
        <Route path="/sales" element={<Sales />} />
        <Route path="/bill" element={<Bill />} />
        <Route path="/billreport" element={<Billreport />} />
        <Route path="/billpayment" element={<BillPayment />} />
        <Route path="/printdata" element={<Printdata />} />
        <Route path="/addpurchase" element={<Addpurchase />} />
        <Route path="/purchasereport" element={<Purchasereport />} />
        <Route path="/paymentupdate" element={<Paymentupdate />} />
        <Route path="/editsales" element={<Editsales />} />
      </Routes>
    </>
  );
};

export default App;
