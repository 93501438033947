import Nav from "./Nav";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { link } from "./url";
const AddSales = () => {
  const [message, setmessage] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checked state
    setmessage(!isChecked ? 1 : 0); // Update the message state
  };
  var co = 0;
  var MyDate = new Date();
  var MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + MyDate.getDate()).slice(-2);

  const [paymentdate, setPaymentdate] = useState(MyDateString);
  const [Id, setId] = useState("");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [area, setArea] = useState("");
  const [Product, setProduct] = useState("");
  const [Price, setPrice] = useState("");
  const [Sales, setSales] = useState(MyDateString);
  const [Mode, setMode] = useState("Daily");
  const [week, setWeek] = useState("");
  const [search, setSearch] = useState("");
  const [value, setvalue] = useState([]);
  const [amount, setAmount] = useState("");
  const [modalShiftData, setModalShiftData] = useState("");

  useEffect(() => {
    axios
      .post(link + "salman/searchcustomer", {
        search: search,
      })
      .then((Response) => {
        setvalue(Response.data);
      });
  }, []);
  const submitdata = () => {
    var value = {
      cid: Id,
      pname: Product,
      price: Price,
      salesdate: Sales,
      day: week,
      mode: Mode,
      balance: Price - amount,
      paymentdate: paymentdate,
      amount: amount,
      shift: modalShiftData,
      message: message,
      mobile: mobile,
    };

    axios.post(link + "salman/addsales", value).then((res) => {
      alert("success");
      // setId("");
      setProduct("");
      setPrice("");
      setSales("");
      setWeek("");
      setMode("");
      setPaymentdate("");
      setAmount("");
      setModalShiftData("");
      setmessage(0);
      setIsChecked(!isChecked);
    });
  };
  const checkmode = () => {
    if (Mode === "Weekly") {
      return (
        <div className="form-floating mt-3 mb-3">
          <select
            className="form-select"
            setvalue={week}
            onChange={(e) => setWeek(e.target.value)}
          >
            <option>sunday</option>
            <option>monday</option>
            <option>tuesday</option>
            <option>wednesday</option>
            <option>thursday</option>
            <option>friday</option>
            <option>saturday</option>
          </select>
          <label htmlFor="email">Select Day for collection</label>
        </div>
      );
    } else if (Mode === "Monthly") {
      return (
        <div className="form-floating mt-3 mb-3">
          <input
            type="text"
            className="form-control"
            value={week}
            onChange={(e) => setWeek(e.target.value)}
            placeholder="enter collection date"
          />
          <label htmlFor="email">Select Date for collection</label>
        </div>
      );
    }
  };
  const Search = (e) => {
    if (e.split("-").length !== 4) {
      setSearch(e);
    } else {
      var n = e.split("-");
      setId(n[0]);
      setMobile(n[3]);
      setName(n[1]);
      setArea(n[2]);
    }
  };
  return (
    <>
      <Nav />
      <div style={{ marginTop: "15%" }}>
        <h1>Add Sales</h1>
        <div className="form-floating mb-3 mt-3">
          <input
            list="browsers"
            id="browser"
            className="form-control"
            placeholder="Search"
            onChange={(e) => Search(e.target.value)}
          />
          <datalist id="browsers">
            {value.map((s) => {
              return (
                <option key={co++}>
                  {s[0] + "-" + s[1] + "-" + s[2] + "-" + s[3]}
                </option>
              );
            })}
          </datalist>
          <label htmlFor="email">Search data with name mobile or area</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={Id}
            placeholder="Enter Name"
            disabled
          />
          <label htmlFor="email">Id</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={name}
            placeholder="Enter Name"
            disabled
          />
          <label htmlFor="email">Name</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            value={mobile}
            placeholder="Enter Mobile"
            disabled
          />
          <label htmlFor="email">Mobile</label>
        </div>
        <div className="form-floating mt-3 mb-3">
          <input
            type="text"
            className="form-control"
            value={area}
            placeholder="Enter Area"
            disabled
          />
          <label htmlFor="pwd">Area</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setProduct(e.target.value)}
            value={Product}
            placeholder="Enter Product"
          />
          <label htmlFor="email">Product</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            onChange={(e) => setPrice(e.target.value)}
            value={Price}
            placeholder="Enter Price"
          />
          <label htmlFor="email">Price</label>
        </div>
        <div className="form-floating mt-3 mb-3">
          <input
            type="date"
            className="form-control"
            onChange={(e) => setSales(e.target.value)}
            value={Sales}
            placeholder="Enter Area"
          />
          <label htmlFor="pwd">Sales Date</label>
        </div>
        <div className="form-floating mt-3 mb-3">
          <input
            type="text"
            className="form-control"
            value={amount}
            placeholder="Enter Area"
            onChange={(e) => setAmount(e.target.value)}
          />
          <label htmlFor="pwd">Amount</label>
        </div>
        <div className="form-floating mt-3 mb-3">
          <input
            type="date"
            className="form-control"
            onChange={(e) => setPaymentdate(e.target.value)}
            value={paymentdate}
            placeholder="Enter paymentdate"
          />
          <label htmlFor="pwd">Next Payment Date</label>
        </div>
        <div className="form-floating mt-3 mb-3">
          <select
            onChange={(e) => setMode(e.target.value)}
            className="form-select"
            setvalue={Mode}
          >
            <option>Daily</option>
            <option>Monthly</option>
            <option>Weekly</option>
            <option>Continue</option>
          </select>
        </div>
        <select
          onChange={(e) => {
            setModalShiftData(e.target.value);
          }}
          value={modalShiftData}
          className="form-select"
        >
          <option>{modalShiftData}</option>
          <option>G</option>
          <option>M</option>
          <option>E</option>
        </select>
        {checkmode()}
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        Message Required
        <input
          type="submit"
          className="btn btn-primary"
          onClick={submitdata}
          style={{ width: "100%" }}
        />
      </div>
    </>
  );
};

export default AddSales;
