import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Nav from "./Nav";
import { link } from "./url";
const Customer = () => {
  const [data, setData] = useState([]);
  const [value, setvalue] = useState([]);
  useEffect(() => {
    axios.get(link + "salman/").then((response) => {
      setData(response.data);
      setvalue(response.data);
    });
  }, []);
  const deletec = (i) => {
    axios.post(link + "salman/deletecustomer", { id: i });
    window.location.reload();
  };
  const searchdata = (e) => {
    const r = [];
    for (var k of value) {
      if (k[2] === null) {
        k[2] = "";
      }
      if (k[3] === null) {
        k[3] = "";
      }
      if (
        k[1].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        k[2].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        k[3].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        (k[0] + "").toLowerCase().indexOf(e.toLowerCase()) !== -1
      ) {
        r.push(k);
      }
    }
    setData(r);
  };
  return (
    <>
      <Nav></Nav>
      <div style={{ marginTop: "10vh" }}>
        <input
          type="search"
          onChange={(e) => searchdata(e.target.value)}
          style={{ marginTop: "10%" }}
          className="form-select"
          placeholder="Search"
        />
        <div className="table-responsive">
          <table className="table table-bordered" id="table_id">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Area</th>
                <th>Mobile</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d) => {
                return (
                  <tr key={d[0]}>
                    <td>{d[0]}</td>
                    <td>{d[1]}</td>
                    <td>{d[2]}</td>
                    <td>{d[3]}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => deletec(d[0])}
                      >
                        delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Customer;
