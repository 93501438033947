import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [x, setx] = useState("");
  const nav = useNavigate();
  useEffect(() => {
    if (window.localStorage.getItem("pro") !== null) {
      nav("/bill");
    }
  });
  return (
    <>
      <div
        style={{
          width: "50%",
          border: "2px solid black",
          marginLeft: "20%",
          textAlign: "center",
          marginTop: "10%",
        }}
      >
        <h1>login</h1>
        <input
          type="password"
          className="form-control"
          value={x}
          onChange={(e) => {
            setx(e.target.value);
          }}
          placeholder="enter password"
          style={{ width: "50%", marginLeft: "25%" }}
        />
        <input
          type="submit"
          className="btn btn-primary"
          onClick={() => {
            if (x === "86438") {
              window.localStorage.setItem("pro", "salman");
            } else {
              window.localStorage.setItem("pro", "user");
            }
            nav("/bill");
          }}
        />
      </div>
    </>
  );
};

export default Login;
