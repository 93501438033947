import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const Nav = () => {
  const nav = useNavigate();
  return (
    <nav className="navbar navbar-expand-sm bg-primary navbar-primary">
      <div className="container-fluid">
        <a className="navbar-brand text-white" href="#">
          Salman
        </a>
        <button
          className="navbar-toggler  text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon  text-white"></span>
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link text-white" to="/home">
                Customer
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-white" to="/addcustomer">
                Add Customer
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-white" to="/addsales">
                Add Sales
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-white" to="/sales">
                Sales
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-white" to="/bill">
                Bill
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-white" to="/billreport">
                Bill Report
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-white" to="/addpurchase">
                Add Purchase
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link text-white" to="/purchasereport">
                Purchase Report
              </NavLink>
            </li>
            <li className="nav-item">
              <p
                style={{ cursor: "pointer" }}
                className="nav-link text-white"
                onClick={() => {
                  window.localStorage.removeItem("pro");
                  nav("/");
                }}
              >
                Logout
              </p>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
