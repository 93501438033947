import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Nav from "./Nav";
import { link } from "./url";
const Billreport = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [value, setvalue] = useState([]);
  const [v, setv] = useState([]);
  var MyDate = new Date();
  var MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + MyDate.getDate()).slice(-2);
  const [date, setdate] = useState(MyDateString);
  const [date1, setdate1] = useState(MyDateString);

  useEffect(() => {
    axios
      .post(link + "salman/billreport", {
        curdate: date,
      })
      .then((response) => {
        setData(response.data["value"]);
        setTotal(response.data["total"]);
        setvalue(response.data["value"]);
        setCount(response.data["count"]);
      });
  }, [v]);
  const searchdata = (e) => {
    const r = [];
    for (var k of value) {
      if (k[5] === null) {
        k[5] = "";
      }
      if (k[4] === null) {
        k[4] = "";
      }
      if (
        k[3].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        k[4].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        k[5].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        (k[4] + "").toLowerCase().indexOf(e.toLowerCase()) !== -1
      ) {
        r.push(k);
      }
    }
    setData(r);
  };

  const submitdate = () => {
    axios
      .post(link + "salman/billfulreport", {
        curdate: date,
        curdate1: date1,
      })
      .then((response) => {
        setData(response.data["value"]);
        setTotal(response.data["total"]);
        setvalue(response.data["value"]);
        setCount(response.data["count"]);
      });
  };
  const deletec = (e) => {
    axios.post(link + "salman/deletebill", { d: e }).then((response) => {
      setv([...v, e[0]]);
    });
  };
  return (
    <>
      <Nav />

      <div style={{ marginTop: "15%" }}>
        <div className="row">
          <div className="col-6">
            <input
              type="text"
              value={date}
              onChange={(e) => setdate(e.target.value)}
              className="form-select"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              value={date1}
              onChange={(e) => setdate1(e.target.value)}
              className="form-select"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-6">
            <input
              type="submit"
              onClick={submitdate}
              className="btn btn-danger"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <input
          type="search"
          onChange={(e) => searchdata(e.target.value)}
          style={{ marginTop: "0%" }}
          className="form-select"
          placeholder="Search"
        />
        {window.localStorage.getItem("pro") === "salman" ? (
          <h1>
            Total {total} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Count {count}
          </h1>
        ) : (
          <h1>Count {count}</h1>
        )}

        <div className="table-responsive">
          <table className="table table-bordered" id="table_id">
            <thead>
              <tr>
                <th>Cid</th>
                <th>Sid</th>
                <th>Name</th>
                <th>Area</th>
                <th>Mobile no</th>
                <th>Pname</th>
                <th>Price</th>
                <th>Balance</th>
                <th>Paid</th>
                <th>Paid Date</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d) => {
                return (
                  <tr key={d[0]}>
                    <td>{d[1]}</td>
                    <td>{d[2]}</td>
                    <td>{d[3]}</td>
                    <td>{d[4]}</td>
                    <td>{d[5]}</td>
                    <td>{d[6]}</td>
                    <td>{d[7]}</td>
                    <td>{d[8]}</td>
                    <td>{d[9]}</td>
                    <td>{d[10]}</td>

                    <td>
                      {window.localStorage.getItem("pro") === "salman" ? (
                        <button
                          className="btn btn-danger"
                          onClick={() => deletec(d)}
                        >
                          delete
                        </button>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Billreport;
